@import 'minima';
@import 'trac';

/* https://coolors.co/ef6461-e4b363-2f4858-fbfbfb-201a23 */

/* https://coolors.co/fbfbfb-d62839-120309-175676-094074 */

:root {
    --space-4x: 3rem;
    --space-2x: 1.5rem;
    --space-1x: .75rem;

    --back-color: #175676;
    --text-color: #120309;
    --text-color-light: #685e62;
    --headings-color: #094074;
    --links-color: #175676;
    --active-links-color: #d62839;
    --meta-color: #d62839;
    --footer-color: #685e62;
    --highlight-color: #fff;
    --wrapper-color: #fff;
    
    --large-title-size: 2rem;
    --medium-title-size: 1.5rem;
    --small-title-size: 1.5rem;
}

body {
    font-family: 'Proza Libre', sans-serif;
    font-size: 1rem;
    margin: 0;
    color: var(--wrapper-color);
    background-color:var(--back-color);
}
.wrapper {
    margin: auto;
    padding: var(--space-4x);
    max-width: 40em;
    background-color: var(--wrapper-color);    
    color: var(--text-color);
}
.page-content {
    padding: 0;
    flex-basis: auto;
}

.site-footer {
    padding: 0;
    border-top: none;
}
.footer-col-wrapper {
    color: var(--footer-color);
}

h1, h2, h3, h4, h5, h6, .site-title, .site-nav {
    font-family: 'Cormorant Garamond', serif;
    font-weight: 600;
    margin-top: 0;
    line-height: 1;
}
h2 {
    font-size: var(--large-title-size);
    margin-bottom: var(--space-2x);
}
h3 {
    font-size: var(--medium-title-size);
    margin-bottom: var(--space-2x);
}
p {
    margin-top: 0;
    margin-bottom: var(--space-1x);
    line-height: 1.8rem;

    &:last-of-type {
        margin: 0;
    }    
}
a, a:visited {
    color: var(--links-color);
    text-decoration: underline;
}
a:hover, a:active {
    color: var(--active-links-color);
}


h1.site-title {
    margin-bottom: 0;
    font-size: var(--medium-title-size);
}
p.site-title {
    font-size: var(--small-title-size);
    font-weight: bold;
}
.site-title a {
    color: #201a23;
}
.site-header {
    border: none;
    
    .wrapper {
        border-top: solid 6px var(--meta-color);
    }

    p {
        margin: 0;
    }
}


.post-list {
    li, article {
        margin: 0 0 var(--space-4x) 0;
        padding-bottom: var(--space-4x);
    
        &:last-of-type {
            padding-bottom: 0;
        }
    }
    
    p {
        color: var(--text-color-light);
    }
}


.post-title, .post-title a, .post-list h3, .post-list h3 a {
    font-size: var(--large-title-size);
    margin-bottom: var(--space-2x);
    color: var(--headings-color);
    text-decoration: none;
}
.post-meta, .post-footer {
    font-size: .7rem;
    text-transform: uppercase;
    color: var(--meta-color);
}
.post-meta {
    margin-bottom: var(--space-2x);
}
.post-content,
.post-summary {
    margin: 0 0 var(--space-2x) 0;
    
    h1 {
        font-size: var(--large-title-size);
    }
    
    h2 {
        font-size: var(--medium-title-size);
    }
    
    h1, h2, h3, h4, h5, h6 {
        color: var(--headings-color);
        line-height: 1;
        margin-top: var(--space-4x);
    }
}


.social-media-list {
    overflow: hidden;
    white-space: nowrap;
}


.highlighter-rouge .highlight {
    background-color: var(--highlight-color)
}
pre, code, kbd {
    background-color: var(--highlight-color)
}


.series {
    h3 {
        margin-top: var(--space-1x);
    }

    h5 {
        margin-bottom: var(--space-1x);
        font-size: .7rem;
        font-weight: normal;
        text-transform: uppercase;
        color: var(--meta-color);
        font-family: 'Proza Libre', sans-serif;
    }
}

@media only screen and (max-width: 600px) {
    nav.site-nav {
        right: var(--space-1x);
        top: calc(var(--space-1x) + 6px);
    }
} 

@media only screen and (min-width: 768px) {
    :root {
        --space-4x: 4rem;
        --space-2x: 2rem;
        --space-1x: 1rem;
            
        --large-title-size: 3rem;
        --medium-title-size: 2rem;
        --small-title-size: 1.5rem;
    }
}
