/* https://github.com/mpchadwick/pygments-high-contrast-stylesheets */

.highlight .hll { background-color: #ffffcc; }
.highlight { background: #ffffff; }
.highlight .c { font-style: italic; color: #767665; }
.highlight .err { color: #a61717; background-color: #e3d2d2; }
.highlight .k { font-weight: bold; }
.highlight .o { font-weight: bold; }
.highlight .ch { font-style: italic; color: #767665; }
.highlight .cm { font-style: italic; color: #767665; }
.highlight .cp { font-weight: bold; color: #757575; }
.highlight .cpf { font-style: italic; color: #767665; }
.highlight .c1 { font-style: italic; color: #767665; }
.highlight .cs { font-weight: bold; font-style: italic; color: #757575; }
.highlight .gd { color: #000000; background-color: #ffdddd; }
.highlight .ge { font-style: italic; }
.highlight .gr { color: #aa0000; }
.highlight .gh { color: #757575; }
.highlight .gi { color: #000000; background-color: #ddffdd; }
.highlight .go { color: #767676; }
.highlight .gp { color: #555555; }
.highlight .gs { font-weight: bold; }
.highlight .gu { color: #747474; }
.highlight .gt { color: #aa0000; }
.highlight .kc { font-weight: bold; }
.highlight .kd { font-weight: bold; }
.highlight .kn { font-weight: bold; }
.highlight .kp { font-weight: bold; }
.highlight .kr { font-weight: bold; }
.highlight .kt { color: #445588; font-weight: bold; }
.highlight .m { color: #008080; }
.highlight .s { color: #976d3b; }
.highlight .na { color: #008080; }
.highlight .nb { color: #757575; }
.highlight .nc { color: #445588; font-weight: bold; }
.highlight .no { color: #008080; }
.highlight .ni { color: #800080; }
.highlight .ne { color: #990000; font-weight: bold; }
.highlight .nf { color: #990000; font-weight: bold; }
.highlight .nn { color: #555555; }
.highlight .nt { color: #000080; }
.highlight .nv { color: #008080; }
.highlight .ow { font-weight: bold; }
.highlight .w { color: #767676; }
.highlight .mb { color: #008080; }
.highlight .mf { color: #008080; }
.highlight .mh { color: #008080; }
.highlight .mi { color: #008080; }
.highlight .mo { color: #008080; }
.highlight .sa { color: #976d3b; }
.highlight .sb { color: #976d3b; }
.highlight .sc { color: #976d3b; }
.highlight .dl { color: #976d3b; }
.highlight .sd { color: #976d3b; }
.highlight .s2 { color: #976d3b; }
.highlight .se { color: #976d3b; }
.highlight .sh { color: #976d3b; }
.highlight .si { color: #976d3b; }
.highlight .sx { color: #976d3b; }
.highlight .sr { color: #767600; }
.highlight .s1 { color: #976d3b; }
.highlight .ss { color: #976d3b; }
.highlight .bp { color: #757575; }
.highlight .fm { color: #990000; font-weight: bold; }
.highlight .vc { color: #008080; }
.highlight .vg { color: #008080; }
.highlight .vi { color: #008080; }
.highlight .vm { color: #008080; }
.highlight .il { color: #008080; }
